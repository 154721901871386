.modalContainer {
  position: relative;
  width: 100%;
  max-width: 500px;
  min-height: 362px;
  overflow: hidden;
  margin: 0px auto;
}

.modal-title {
  margin: 20px 0;
  font-size: 1.4rem;
  font-weight: 600;
  text-align: left;
  color: #545454;
}

ul {
  padding-left: 0rem;
}

.detalhes {
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.2px;
  margin: 20px 0;
  width: auto;
  justify-content: center;
  color: var(--primary-color-background);
}

.user-data {
  width: 100%;
  padding: 1rem;
  margin-top: 1rem;
  /* 
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee; */

  display: flex;
  gap: 2rem;
  justify-content: center;
  align-items: center;
}

.user-data select {
  border: 0;
  font-size: 1.2rem;
  font-weight: 500;
}

.user-data p {
  font-size: 1.2rem;
  font-weight: 500;
}

.user-data p i {
  font-size: 0.9rem;
  font-weight: 300;
  font-style: normal;
}

.step-1 svg {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
  stroke: #007bff;
}

.subtitle {
  width: 100%;
  margin: 0px 0px 30px 0px;
  text-align: left;
  font-size: 1.1rem;
  font-weight: 500;
  color: #545454;
}

.finish {
  height: 50px;
  margin: 15px 0;
  width: 100%;
  text-align: center;
}

.step-1 p {
  margin: 1rem 0;
  text-align: left;
  font-size: 12px;
}

.step-1 ul {
  width: 100%;
  /* margin-top: 2rem; */
  list-style: none;
  border: 1px solid #eee;
  border-radius: 10px;
}

.step-1 ul li {
  width: 100%;
  height: 2.5rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.step-1 ul li:not(:last-child) {
  border-bottom: 1px solid #eee;
}

.step-1 ul li div {
  width: 33%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.step-1 ul li .title {
  font-size: 1rem;
  font-weight: 600;
  justify-content: center;
}

.step-1 ul li .value {
  font-size: 0.8rem;
  font-weight: 400;
  justify-content: center;
}

.step-2 svg {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
  stroke: #007bff;
}

.step-2 .aula-data {
  width: 100%;
  height: 4.5rem;
  padding: 1rem;
  border: 1px solid #eee;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.step-2 .aula-data div {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.step-2 .aula-data .title {
  font-size: 1.2rem;
  font-weight: 500;
  justify-content: flex-start;
}

.step-2 .aula-data .date {
  justify-content: flex-start;
  color: #585858c4;
  text-align: left;
  font-size: 0.9rem;
}

.step-2 .column {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.step-2 .rows {
  display: flex;
  gap: 2rem;
}

.step-2 > p {
  margin-top: 1rem;
  text-align: left;
}

.step-2 ul {
  width: 100%;
  /* margin-top: 2rem; */
  list-style: none;
  border: 1px solid #eee;
  border-radius: 10px;
}

.loading-gif {
  margin-top: 80px;
}

.step-2 ul li {
  width: 100%;
  height: 4rem;
  padding: 1rem;
  display: flex;
  /* justify-content: space-around; */
  align-items: center;
  cursor: pointer;
  color: #585858c4;
  text-align: left;
  font-size: 0.9rem;
}

.step-2 ul li:not(:last-child) {
  border-bottom: 1px solid #eee;
}

.step-2 ul li.selected {
  background: #007bff1f;
  border-color: #007bff;
}

.step-2 ul li.selected:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.step-2 ul li.selected:first-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.step-2 ul li svg {
  margin-right: 1rem;
}

/* .step-2 > div > div {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
} */

.step-2 button {
  width: 33%;
  /* height: 3rem; */
  border: 0;
}

.step-2 .btn-goBack {
  width: auto;
  padding: 0;
  margin-top: 0;
  border: 0;
  background: transparent !important;
  color: #007bff !important;
  display: flex;
  align-items: center;
}

.step-2 .btn-goBack svg {
  width: 1.6rem;
  height: 1.6rem;
}

.buttons {
  display: flex;
  justify-content: center;
}



button.primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

button.simple {
  border: 0;
  background: transparent !important;
  color: #007bff !important;
}
